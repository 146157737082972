import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Timeline } from 'react-twitter-widgets'

import { Link } from "gatsby"

import Seo from "../components/seo"
import "../components/main.css"

const Honten = () => (
    <>
        <Seo title="VRChat PC本店" />
        <div className="header">
            <h1>バーチャル授乳Cafeキタリナ<br />VRChat PC本店のページ</h1>
        </div>
        <div className="content">
            <div className="images">
                <StaticImage
                    src="../images/kitarina-poster-honten.jpg"
                    loading="eager"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt=""
                    style={{ maxWidth: `350px` }}
                />
            </div>
            <div className="inner-content">
                <Link to="/" className="text">←トップに戻る</Link>
                <h1>PC本店の概要</h1>
                <p className="text">オープン: 2020年2月2日</p>
                <p className="text">営業日時: 毎週日曜日15:00～17:00</p>
                <p className="text">管理者: ホシライム(Hoshi_Lime)</p>
                <hr />
                <Timeline dataSource={{ sourceType: "profile", screenName: "CafeKitarina" }} options={{ lang: "ja", dnt: true, height: "500", chrome: "nofooter", }} />
                <hr />
                <p>
                    当サイトの内容、テキスト、画像等の無断転載・無断使用を固く禁じます。<br />
                    また、まとめサイト等への引用を厳禁致します。<br />
                    お問い合わせはDMまでご連絡お願い致します。
                </p>
            </div>
        </div>
        <div className="footer">
            <p>&copy; {new Date().getFullYear()} バーチャル授乳Cafeキタリナ</p>
        </div>
    </>
)

export default Honten
